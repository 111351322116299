import { authorize, checkout, getSession, shareSession } from '../../api.js';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal.js';
import i18n from '../../i18n.js';
import { notifications } from '@mantine/notifications';
import Carousel from 'nuka-carousel';
import PaymentModal from '../PaymentModal/PaymentModal.js';
import PropTypes from 'prop-types';
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
    FaArrowLeft,
    FaArrowRight,
    FaCartShopping,
    FaCheck,
    FaCirclePlus,
    FaDownload,
    FaLightbulb
} from 'react-icons/fa6';
import StyleSelectModal from '../StyleSelectModal/StyleSelectModal.js';
import './Stylizer.css';

function Stylizer() {
    const [currentTip, setCurrentTip] = useState(0);
    const [email, setEmail] = useState('');
    const [genCount, setGenCount] = useState(0.0);
    const [height, setHeight] = useState(0);
    const [isConfirmExportModalOpen, setConfirmExportModalOpen] =
        useState(false);
    const [isConfirmPaymentModalOpen, setConfirmPaymentModalOpen] =
        useState(false);
    const [isFirstLoading, setIsFirstLoading] = useState(false);
    const [isFullscreen, toggleFullscreen] = useState(false);
    const [isModalVisible, setModalVisible] = useState(false);
    const [isSessionActive, setIsSessionActive] = useState(false);
    const [items, setItems] = useState([]);
    const [paymentModalOpen, setPaymentModalOpen] = useState(false);
    const [selectedStyles, setSelectedStyles] = useState([]);
    const [sessionID, setSessionID] = useState('');
    const [sliderIndex, setSliderIndex] = useState(1);
    const [stripeID, setStripeID] = useState('');
    const [styleModalOpen, setStyleModalOpen] = useState(false);
    const [upload, setUpload] = useState('');
    const [width, setWidth] = useState(0);

    const blobToBase64 = async (blob) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result);
            reader.onerror = (err) => reject(err);
            reader.readAsDataURL(blob);
        });
    };

    const closeStyleModal = () => {
        setStyleModalOpen(false);
    };

    const handleCheckoutClick = () => {
        setModalVisible(true);
    };

    const handleCheckoutResume = async () => {
        await checkout(sessionID);
        notifications.show({
            autoClose: 6000,
            color: 'green',
            icon: <FaCheck />,
            loading: false,
            message: i18n.t('toast.payment'),
            title: i18n.t('toast.complete'),
            withCloseButton: false
        });
        setConfirmPaymentModalOpen(true);
    };

    const handleClose = () => {
        setModalVisible(false);
    };

    const handleConfirmCheckout = async () => {
        await checkout(sessionID);
        notifications.show({
            autoClose: 3000,
            color: 'green',
            icon: <FaCheck />,
            loading: false,
            message: i18n.t('toast.completed', {
                amount: `${parseFloat(genCount * 0.5).toFixed(2)}`
            }),
            title: i18n.t('toast.complete'),
            withCloseButton: false
        });
        localStorage.removeItem('session_id');
        sessionStorage.removeItem('styles');
        setModalVisible(false);
        setIsSessionActive(false);
        setItems([]);
    };

    const handleConfirmCurrent = async () => {
        const id = localStorage.getItem('session_id');

        if (id) {
            getSession(id).then(async (session) => {
                const authorization = await authorize(
                    session.id,
                    session.email,
                    process.env.REACT_APP_AMOUNT
                );
                return { authorization, session };
            });
        } else {
            setIsSessionActive(false);
            setConfirmPaymentModalOpen(true);
        }
    };

    const handleDownload = async () => {
        const styles = sessionStorage.getItem('styles');
        const urls = styles ? JSON.parse(styles) : await urlMap();

        await Promise.all(
            Object.entries(urls).map(async ([style, url]) => {
                const link = document.createElement('a');
                link.href = url;
                link.download = `${style}.png`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
        );
    };

    const handleExport = async () => {
        const styles = sessionStorage.getItem('styles');
        const urls = styles ? JSON.parse(styles) : await urlMap();

        await Promise.all(
            Object.entries(urls).map(async ([style, url]) => {
                try {
                    const image = await fetch(url)
                        .then((response) => response.blob())
                        .then((blob) => blobToBase64(blob))
                        .then((base64Str) =>
                            base64Str.replace(/^data:[^;]+;base64,/, '')
                        );
                    urls[style] = image;
                } catch (error) {
                    console.error(`Error [${style}]:`, error);
                }
            })
        );

        await shareSession(sessionID, {
            files: urls,
            recipient: [email],
            subject: `${i18n.t('share.subject')} [${sessionID}]`,
            text: `${i18n.t('share.text')}`
        });
    };

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        const img = new Image();

        img.src = await blobToBase64(file);

        img.onload = function () {
            setHeight(this.height);
            setWidth(this.width);
        };

        setStyleModalOpen(true);
        setItems([]);
        setSelectedStyles([]);
        setUpload(img.src);

        sessionStorage.removeItem('styles');
        event.target.value = null;
    };

    const handleFileInput = useRef(null);
    const imgRefs = useRef([]);
    const keyRefs = useRef([]);
    const styles = process.env.REACT_APP_STYLES.split(',');
    const { t } = useTranslation();
    const tips = [
        i18n.t('tip.one'),
        i18n.t('tip.two'),
        i18n.t('tip.three'),
        i18n.t('tip.four'),
        i18n.t('tip.five'),
        i18n.t('tip.six'),
        i18n.t('tip.seven'),
        i18n.t('tip.eight')
    ];

    const urlMap = async () => {
        const styleMap = {};

        await Promise.all(
            items.map(async (item) => {
                const name = item.key
                    .replace(/\s+/g, '_')
                    .toLowerCase()
                    .split('-')[0];
                const salt = crypto.getRandomValues(new Uint32Array(1))[0];
                const style = `${name}-${salt}`;
                styleMap[style] = item.image;
            })
        );
        sessionStorage.setItem('styles', JSON.stringify(styleMap));

        return styleMap;
    };

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentTip((currentTip + 1) % tips.length);
        }, 6000);
        return () => clearInterval(interval);
    }, [currentTip]);

    useEffect(() => {
        if (keyRefs.current[sliderIndex]) {
            keyRefs.current[sliderIndex].scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
                inline: 'center'
            });
        }
    }, [sliderIndex]);

    useEffect(() => {
        const id = localStorage.getItem('session_id');

        if (id) {
            getSession(id).then((session) => {
                if (session.status) {
                    setEmail(session.email);
                    setGenCount(session.price);
                    setIsSessionActive(true);
                    setSessionID(session.id);
                    setStripeID(session.stripe_id);
                } else {
                    setIsSessionActive(false);
                    setConfirmPaymentModalOpen(true);
                }
            });
        } else {
            setIsSessionActive(false);
        }
    }, [getSession, paymentModalOpen]);

    useEffect(() => {
        const handleBeforeUnload = (e) => {
            e.preventDefault();
            e.returnValue = '';
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    useEffect(() => {
        const toggleFullscreen = async () => {
            const imgElement = imgRefs.current[sliderIndex];

            if (imgElement && isFullscreen) {
                try {
                    if (imgElement.requestFullscreen) {
                        await imgElement.requestFullscreen();
                    } else if (imgElement.webkitRequestFullscreen) {
                        /* Safari */
                        await imgElement.webkitRequestFullscreen();
                    } else if (imgElement.msRequestFullscreen) {
                        /* IE11 */
                        await imgElement.msRequestFullscreen();
                    } else if (imgElement.webkitRequestPresentationMode) {
                        /* iOS */
                        await imgElement.webkitRequestPresentationMode(
                            'fullscreen'
                        );
                    } else {
                        console.error(
                            'Fullscreen API is not supported in this browser.'
                        );
                    }
                } catch (error) {
                    console.error('Failed to enter fullscreen mode:', error);
                }
            } else if (!isFullscreen && document.fullscreenElement) {
                try {
                    if (document.exitFullscreen) {
                        await document.exitFullscreen();
                    } else if (document.webkitExitFullscreen) {
                        /* Safari */
                        await document.webkitExitFullscreen();
                    } else if (document.msExitFullscreen) {
                        /* IE11 */
                        await document.msExitFullscreen();
                    } else if (document.webkitExitPresentationMode) {
                        /* iOS */
                        await document.webkitExitPresentationMode();
                    }
                } catch (error) {
                    console.error('Failed to exit fullscreen mode:', error);
                }
            }
        };

        toggleFullscreen();
    }, [isFullscreen, sliderIndex]);

    return (
        <>
            <div className="main">
                {isFirstLoading ? (
                    <div className="overlay">
                        <h2 className="animate-text">
                            {t('header.generating')}
                        </h2>
                        <div className="tip">
                            <FaLightbulb
                                style={{
                                    color: 'yellow',
                                    paddingRight: '10px'
                                }}
                            />
                            {tips[currentTip]}
                        </div>
                    </div>
                ) : (
                    <>
                        <ConfirmExportModal
                            isOpen={isConfirmExportModalOpen}
                            onCheckout={() => handleConfirmCheckout()}
                            onClose={() => setConfirmExportModalOpen(false)}
                            onDownload={() => handleDownload(true)}
                            onEmail={() => handleExport(true)}
                            t={t}
                        />
                        <ConfirmPaymentModal
                            isOpen={isConfirmPaymentModalOpen}
                            onClose={() => setConfirmPaymentModalOpen(false)}
                            onCurrent={() => handleConfirmCurrent()}
                            onNew={() => setPaymentModalOpen(true)}
                            t={t}
                        />
                        <PaymentModal
                            closeModal={() => setPaymentModalOpen(false)}
                            email={email}
                            isModalOpen={paymentModalOpen}
                            setEmail={setEmail}
                        />
                        <StyleSelectModal
                            closeModal={closeStyleModal}
                            ConfirmationModal={ConfirmationModal}
                            genCount={genCount}
                            handleCheckoutClick={handleCheckoutClick}
                            handleCheckoutResume={handleCheckoutResume}
                            handleClose={handleClose}
                            handleConfirmCheckout={handleConfirmCheckout}
                            height={height}
                            isModalOpen={styleModalOpen}
                            isModalVisible={isModalVisible}
                            selectedStyles={selectedStyles}
                            sessionID={sessionID}
                            setGenCount={setGenCount}
                            setIsFirstLoading={setIsFirstLoading}
                            setIsModalOpen={setStyleModalOpen}
                            setItems={setItems}
                            setSelectedStyles={setSelectedStyles}
                            stripeID={stripeID}
                            styles={styles}
                            upload={upload}
                            uploadRef={handleFileInput}
                            width={width}
                        />

                        <input
                            accept="image/*;./*"
                            id="fileInput"
                            onChange={handleFileChange}
                            ref={handleFileInput}
                            style={{ display: 'none' }}
                            type="file"
                        />

                        <>
                            <>
                                {!items.length && (
                                    <>
                                        <div className="header">
                                            <h1>
                                                {isSessionActive && (
                                                    <div
                                                        className="money-box"
                                                        style={
                                                            !genCount > 0
                                                                ? {
                                                                      display:
                                                                          'none'
                                                                  }
                                                                : {}
                                                        }
                                                    >
                                                        <span className="price">
                                                            $
                                                            {parseFloat(
                                                                genCount * 0.5
                                                            ).toFixed(2)}
                                                        </span>
                                                        <button
                                                            className="header-button"
                                                            onClick={
                                                                handleCheckoutClick
                                                            }
                                                        >
                                                            <FaCartShopping />
                                                        </button>
                                                    </div>
                                                )}
                                            </h1>

                                            <ConfirmationModal
                                                cancelButtonText={t('cancel')}
                                                confirmButtonText={t('confirm')}
                                                content={t('payment.question')}
                                                onClose={handleClose}
                                                onConfirm={
                                                    handleConfirmCheckout
                                                }
                                                show={isModalVisible}
                                                title={t('payment.title')}
                                            />
                                        </div>
                                        <div className="intro-container">
                                            <div className="Vibe">
                                                <h1>VIBE</h1>
                                                <p>{t('description')}</p>
                                            </div>
                                            <div className="tag-line">
                                                <p>{t('description')}</p>
                                            </div>
                                            <div className="design">
                                                <div className="button-holder">
                                                    {isSessionActive ? (
                                                        <button
                                                            className="glowing-btn"
                                                            onClick={() =>
                                                                document
                                                                    .getElementById(
                                                                        'fileInput'
                                                                    )
                                                                    .click()
                                                            }
                                                        >
                                                            <span className="glowing-txt">
                                                                {t(
                                                                    'glow.design.begin'
                                                                )}
                                                                <span className="faulty-letter">
                                                                    {t(
                                                                        'glow.design.middle'
                                                                    )}
                                                                </span>
                                                                {t(
                                                                    'glow.design.end'
                                                                )}
                                                            </span>
                                                        </button>
                                                    ) : (
                                                        <button
                                                            className="glowing-btn"
                                                            onClick={
                                                                setPaymentModalOpen
                                                            }
                                                        >
                                                            <span className="glowing-txt">
                                                                {t(
                                                                    'glow.start.begin'
                                                                )}
                                                                <span className="faulty-letter">
                                                                    {t(
                                                                        'glow.start.middle'
                                                                    )}
                                                                </span>
                                                                {t(
                                                                    'glow.start.end'
                                                                )}
                                                            </span>
                                                        </button>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="how-to-span">
                                                <p>{t('help.how_to')}</p>
                                            </div>
                                            <div className="how-to-photo"></div>
                                            <p className="transition-how-to-top">
                                                {t('help.how_to')}
                                            </p>
                                            <div className="transition-how-to"></div>
                                            <p className="transition-how-to-bottom">
                                                {t('help.intro')}
                                            </p>
                                            <div className="intro-span">
                                                <p>{t('help.intro')}</p>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </>
                        </>
                        {items.length > 0 && (
                            <>
                                <div className="carousel-container">
                                    <div className="header">
                                        <h1>
                                            <div
                                                className="money-box"
                                                style={
                                                    isSessionActive &&
                                                    !genCount > 0
                                                        ? { display: 'none' }
                                                        : {}
                                                }
                                            >
                                                <span className="price">
                                                    $
                                                    {parseFloat(
                                                        genCount * 0.5
                                                    ).toFixed(2)}
                                                </span>
                                                <button
                                                    className="header-button"
                                                    onClick={
                                                        handleCheckoutClick
                                                    }
                                                >
                                                    <FaCartShopping />
                                                </button>
                                            </div>
                                        </h1>
                                        <button
                                            className="upload-button"
                                            onClick={() => {
                                                handleFileInput.current.click();
                                            }}
                                        >
                                            <FaCirclePlus className="upload-icon" />
                                        </button>
                                        <button
                                            className="header-button"
                                            disabled={items.some(
                                                (item) => item.loading
                                            )}
                                            onClick={setConfirmExportModalOpen}
                                        >
                                            <FaDownload />
                                        </button>
                                        <ConfirmationModal
                                            cancelButtonText={t('cancel')}
                                            confirmButtonText={t('confirm')}
                                            content={t('payment.question')}
                                            onClose={handleClose}
                                            onConfirm={handleConfirmCheckout}
                                            show={isModalVisible}
                                            title={t('payment.title')}
                                        />
                                    </div>
                                    <Carousel
                                        beforeSlide={(_, v) =>
                                            setSliderIndex(v)
                                        }
                                        className="carouselZ"
                                        defaultControlsConfig={{
                                            nextButtonStyle: {
                                                backgroundColor: 'transparent',
                                                bottom: '0',
                                                padding: '0'
                                            },
                                            prevButtonStyle: {
                                                backgroundColor: 'transparent',
                                                bottom: '0',
                                                padding: '0'
                                            },
                                            nextButtonText: (
                                                <div
                                                    className="icon-circle"
                                                    style={{
                                                        marginRight: '15px'
                                                    }}
                                                >
                                                    <FaArrowRight
                                                        size={'2em'}
                                                    />
                                                </div>
                                            ),
                                            prevButtonText: (
                                                <div
                                                    className="icon-circle"
                                                    style={{
                                                        marginLeft: '15px'
                                                    }}
                                                >
                                                    <FaArrowLeft size={'2em'} />
                                                </div>
                                            )
                                        }}
                                        slideIndex={sliderIndex}
                                        swiping={true}
                                    >
                                        <div className="slide-image-container">
                                            <img
                                                alt={'Original'}
                                                className="carousel-image"
                                                ref={(el) =>
                                                    (imgRefs.current[0] = el)
                                                }
                                                src={upload}
                                            />
                                        </div>
                                        {items
                                            .filter((slide) => !slide.loading)
                                            .map((slide, index) => {
                                                return (
                                                    <>
                                                        <div
                                                            className={`slide-image-container ${sliderIndex === index + 1 ? 'selected' : ''}`}
                                                        >
                                                            <img
                                                                alt={slide.key}
                                                                className="carousel-image"
                                                                onClick={() =>
                                                                    toggleFullscreen(
                                                                        false
                                                                    )
                                                                }
                                                                ref={(el) =>
                                                                    (imgRefs.current[
                                                                        index +
                                                                            1
                                                                    ] = el)
                                                                }
                                                                src={
                                                                    slide.image
                                                                }
                                                            />
                                                        </div>
                                                    </>
                                                );
                                            })}
                                    </Carousel>

                                    <div className="carousel-bottom-container">
                                        <div className="carousel-keys-container">
                                            <div
                                                className={`carousel-keys ${sliderIndex === 0 ? 'carousel-key-selected' : ''}`}
                                                key="Original"
                                                onClick={() => {
                                                    setSliderIndex(0);
                                                }}
                                                ref={(el) =>
                                                    (keyRefs.current[0] = el)
                                                }
                                            >
                                                <div className="text">
                                                    Original
                                                </div>
                                            </div>
                                            {items.map((slide, index) => (
                                                <div
                                                    className={`carousel-keys ${sliderIndex === index + 1 ? 'carousel-key-selected' : ''}`}
                                                    key={slide.key}
                                                    onClick={() => {
                                                        setSliderIndex(
                                                            index + 1
                                                        );
                                                    }}
                                                    ref={(el) =>
                                                        (keyRefs.current[
                                                            index + 1
                                                        ] = el)
                                                    }
                                                >
                                                    <div
                                                        className={`text ${slide.loading ? 'blinking-text' : ''}`}
                                                    >
                                                        {
                                                            slide.key.split(
                                                                '-'
                                                            )[0]
                                                        }
                                                        {slide.loading && (
                                                            <div className="spinner"></div>
                                                        )}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </>
                )}
            </div>
        </>
    );
}

function ConfirmExportModal({
    isOpen,
    onClose,
    onEmail,
    onDownload,
    onCheckout,
    t
}) {
    if (!isOpen) {
        return null;
    }
    const [step, setStep] = useState(1);

    const handleDownloadOrEmail = (choice) => {
        if (choice) {
            onDownload();
        } else {
            onEmail();
        }
        setStep(2);
    };

    const handleContinueOrCheckout = (choice) => {
        if (choice) {
            onCheckout();
            onClose();
        } else {
            onClose();
        }
    };

    return (
        <ConfirmationModal
            cancelButtonText={step === 1 ? t('email') : t('continue')}
            confirmButtonText={step === 1 ? t('download') : t('checkout')}
            content={step === 1 ? t('share.question') : t('payment.question')}
            onCancel={() =>
                step === 1
                    ? handleDownloadOrEmail(false)
                    : handleContinueOrCheckout(false)
            }
            onClose={onClose}
            onConfirm={() =>
                step === 1
                    ? handleDownloadOrEmail(true)
                    : handleContinueOrCheckout(true)
            }
            show={isOpen}
            title={() => (step === 1 ? t('share.title') : t('payment.title'))}
        />
    );
}

ConfirmExportModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onCheckout: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onDownload: PropTypes.func.isRequired,
    onEmail: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};

function ConfirmPaymentModal({ isOpen, onClose, onCurrent, onNew, t }) {
    if (!isOpen) {
        return null;
    }

    const handleCurrentOrNew = (choice) => {
        if (choice) {
            onCurrent();
            onClose();
        } else {
            onNew();
            onClose();
        }
    };

    return (
        <ConfirmationModal
            cancelButtonText={t('payment.new')}
            confirmButtonText={t('payment.current')}
            content={t('payment.options')}
            onCancel={handleCurrentOrNew(false)}
            onClose={onClose}
            onConfirm={handleCurrentOrNew(true)}
            show={isOpen}
            title={t('payment.option')}
        />
    );
}

ConfirmPaymentModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onCurrent: PropTypes.func.isRequired,
    onNew: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};

export default Stylizer;
