export const authorize = async (id, email, gens) => {
    const auth = await fetch(`${process.env.REACT_APP_API}/pay/hold/`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            mode: 'cors',
            'X-App': `${process.env.REACT_APP_KEY}`
        },
        body: JSON.stringify({
            customer: {
                id: id,
                email: email,
                generations: gens
            }
        })
    })
        .then((response) => {
            if (!response.ok) {
                throw new Error(`Authorize: ${response.status}`);
            }

            return response.json();
        })
        .catch((error) => {
            console.error('Error:', error);
        });

    return auth;
};

export const checkout = async (id) => {
    const transaction = await fetch(
        `${process.env.REACT_APP_API}/pay/checkout/${id}`,
        {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                mode: 'cors',
                'X-App': `${process.env.REACT_APP_KEY}`
            },
            body: JSON.stringify({})
        }
    )
        .then((response) => {
            if (!response.ok) {
                throw new Error(`Checkout: ${response.status}`);
            }

            return response.json();
        })
        .catch((error) => {
            console.error('Error:', error);
        });

    return transaction;
};

export const createSession = async (email) => {
    const session = await fetch(`${process.env.REACT_APP_API}/session/create`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            mode: 'cors',
            'X-App': `${process.env.REACT_APP_KEY}`
        },
        body: JSON.stringify({
            session: { email: email }
        })
    })
        .then((response) => {
            if (!response.ok) {
                throw new Error(`Session[create]: ${response.status}`);
            }

            return response.json();
        })
        .catch((error) => {
            throw error;
        });

    return session;
};

export const getSession = async (id) => {
    const session = await fetch(`${process.env.REACT_APP_API}/session/${id}/`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            mode: 'cors',
            'X-App': `${process.env.REACT_APP_KEY}`
        }
    })
        .then((response) => {
            if (!response.ok) {
                throw new Error(`Session[get]: ${response.status}`);
            }

            return response.json();
        })
        .catch((error) => {
            console.error('Error:', error);
        });

    return session;
};

export const getStyle = async (
    id,
    category,
    accuracy,
    furniture,
    height,
    image,
    service,
    style,
    width
) => {
    const styled = await fetch(
        `${process.env.REACT_APP_API}/style/${service}/${id}`,
        {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                mode: 'cors',
                'X-App': `${process.env.REACT_APP_KEY}`
            },
            body: JSON.stringify({
                image: {
                    accuracy,
                    base64: image,
                    category,
                    furniture: furniture,
                    height: parseInt(height, 10),
                    style,
                    width: parseInt(width, 10)
                }
            })
        }
    )
        .then((response) => {
            if (!response.ok) {
                throw new Error(`Style: ${response.status}`);
            }

            return response.json();
        })
        .catch((error) => {
            console.error('Error:', error);
        });

    return styled.style;
};

export const resumeSession = async (mail) => {
    const session = await fetch(
        `${process.env.REACT_APP_API}/session/resume/`,
        {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                mode: 'cors',
                'X-App': `${process.env.REACT_APP_KEY}`
            },
            body: JSON.stringify({ mail })
        }
    )
        .then((response) => {
            if (!response.ok) {
                throw new Error(`Session[resume]: ${response.status}`);
            }

            return response.json();
        })
        .catch((error) => {
            console.error('Error:', error);
        });

    return session;
};

export const shareSession = async (id, mail) => {
    const session = await fetch(
        `${process.env.REACT_APP_API}/session/export/${id}`,
        {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                mode: 'cors',
                'X-App': `${process.env.REACT_APP_KEY}`
            },
            body: JSON.stringify({ mail })
        }
    )
        .then((response) => {
            if (!response.ok) {
                throw new Error(`Export: ${response.status}`);
            }

            return response.json();
        })
        .catch((error) => {
            console.error('Error:', error);
        });

    return session;
};

export const updateSession = async (id, session) => {
    const update = await fetch(`${process.env.REACT_APP_API}/session/${id}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            mode: 'cors',
            'X-App': `${process.env.REACT_APP_KEY}`
        },
        body: JSON.stringify({ session: session })
    })
        .then((response) => {
            if (!response.ok) {
                throw new Error(`Session[update]: ${response.status}`);
            }

            return response.json();
        })
        .catch((error) => {
            console.error('Error:', error);
        });

    return update;
};

export const upscale = async (
    id,
    category,
    accuracy,
    height,
    image,
    service,
    style,
    width
) => {
    const styled = await fetch(
        `${process.env.REACT_APP_API}/style/${service}/upscale/${id}`,
        {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                mode: 'cors',
                'X-App': `${process.env.REACT_APP_KEY}`
            },
            body: JSON.stringify({
                image: {
                    category,
                    accuracy,
                    base64: image,
                    height: parseInt(height, 10),
                    style,
                    width: parseInt(width, 10)
                }
            })
        }
    )
        .then((response) => {
            if (!response.ok) {
                throw new Error(`Upscale: ${response.status}`);
            }

            return response.json();
        })
        .catch((error) => {
            console.error('Error:', error);
        });

    return styled.style;
};
