import { getStyle } from '../../api.js';
import i18n from '../../i18n.js';
import { notifications } from '@mantine/notifications';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    FaCartShopping,
    FaCircleExclamation,
    FaCirclePlus,
    FaCouch,
    FaHouseChimney
} from 'react-icons/fa6';
import './StyleSelectModal.css';

Modal.setAppElement('#root');

const StyleSelectModal = ({
    ConfirmationModal,
    closeModal,
    genCount,
    handleCheckoutClick,
    handleCheckoutResume,
    handleClose,
    handleConfirmCheckout,
    height,
    isModalOpen,
    isModalVisible,
    selectedStyles,
    sessionID,
    setGenCount,
    setIsFirstLoading,
    setIsModalOpen,
    setItems,
    setSelectedStyles,
    styles,
    upload,
    uploadRef,
    width
}) => {
    const [creativeMode, setCreativeMode] = useState(false);
    const [exteriorMode, setExteriorMode] = useState(false);
    const [furnitureType, setFurnitureType] = useState(null);
    const [original, setOriginal] = useState(
        upload.replace(/^data:[^;]+;base64,/, '')
    );

    const handleAddStyle = (event) => {
        const totalStyles = Object.values(selectedStyles).reduce(
            (acc, currentValue) => acc + currentValue,
            0
        );

        if (totalStyles < 10) {
            setSelectedStyles((prevStyles) => {
                const styleName = event.target.name;
                const count = prevStyles[styleName]
                    ? prevStyles[styleName] + 1
                    : 1;
                return { ...prevStyles, [styleName]: count };
            });
            setGenCount((prevGenCount) => prevGenCount + 1);
        } else {
            notifications.show({
                autoClose: 6000,
                color: 'purple',
                icon: <FaCircleExclamation />,
                loading: false,
                message: i18n.t('toast.maxed', {
                    amount: 10
                }),
                title: i18n.t('toast.max'),
                withCloseButton: false
            });
        }
    };

    const handleRemoveStyle = (event) => {
        const styleName = event.target.name;
        setSelectedStyles((prevStyles) => {
            if (!prevStyles[styleName]) {
                return prevStyles;
            } else if (prevStyles[styleName] > 1) {
                return {
                    ...prevStyles,
                    [styleName]: prevStyles[styleName] - 1
                };
            } else {
                const newStyles = { ...prevStyles };
                delete newStyles[styleName];
                return newStyles;
            }
        });
        setGenCount((prevGenCount) => prevGenCount - 1);
    };

    const handleSubmit = () => {
        setFurnitureType(null);
        setIsFirstLoading(true);
        setIsModalOpen(false);

        const finalGenCount = Object.values(selectedStyles).reduce(
            (acc, currentValue) => acc + currentValue,
            0
        );

        if (finalGenCount >= parseInt(process.env.REACT_APP_AMOUNT * 0.5, 10)) {
            handleCheckoutResume();
            setGenCount(1);
        }

        for (const style in selectedStyles) {
            const amount = selectedStyles[style];
            for (let i = 0; i < amount; i++) {
                const newKey = `${style}-${i + 1}`;

                setItems((prevItems) => {
                    return [...prevItems, { key: newKey, loading: true }];
                });

                getStyle(
                    sessionID,
                    exteriorMode ? 'exterior' : 'interior',
                    creativeMode ? 'creative' : 'accurate',
                    furnitureType,
                    height,
                    original,
                    `${process.env.REACT_APP_SERVICE}`,
                    style.replace(' ', '_').toLowerCase(),
                    width
                ).then(async (gen) => {
                    const image = await fetch(gen)
                        .then((response) => response.blob())
                        .then((blob) => URL.createObjectURL(blob));
                    const key = `${style}-${i + 1}`;

                    setItems((prevItems) => {
                        const index = prevItems.findIndex(
                            (item) => item.key === key
                        );
                        if (index !== -1) {
                            prevItems[index] = { key, image, loading: false };
                        }
                        return [...prevItems];
                    });
                    setIsFirstLoading(false);
                });
            }
        }
    };

    const { t } = useTranslation();

    useEffect(() => {
        setOriginal(upload.replace(/^data:[^;]+;base64,/, ''));
    }, [upload]);

    return (
        <Modal
            className="style-modal"
            contentLabel="Select Styles"
            isOpen={isModalOpen}
            onRequestClose={closeModal}
        >
            <div className="header">
                <h1>
                    <div
                        className="money-box"
                        style={
                            !genCount > 0
                                ? {
                                      display: 'none'
                                  }
                                : {}
                        }
                    >
                        <span className="price">
                            ${parseFloat(genCount * 0.5).toFixed(2)}
                        </span>
                        <button
                            className="header-button"
                            onClick={handleCheckoutClick}
                        >
                            <FaCartShopping />
                        </button>
                    </div>
                </h1>
                <div>
                    {Object.keys(selectedStyles).length > 0 ? (
                        <button
                            className="pulse-button"
                            onClick={() => {
                                handleSubmit();
                            }}
                        >
                            {t('generate')}
                        </button>
                    ) : (
                        <button
                            className="upload-button"
                            onClick={() => {
                                uploadRef.current.click();
                            }}
                        >
                            <FaCirclePlus className="upload-icon" />
                        </button>
                    )}
                </div>
                <div style={{ display: 'none' }}>
                    <button
                        className="header-button"
                        onClick={() => {
                            setExteriorMode(!exteriorMode);
                        }}
                        style={{
                            fill: exteriorMode ? '#e3df04' : 'white'
                        }}
                    >
                        <FaHouseChimney />
                    </button>
                </div>
                <div>
                    <button
                        className="header-button"
                        onClick={() => {
                            setCreativeMode(!creativeMode);
                        }}
                        style={{
                            fill: creativeMode ? '#e3df04' : 'white'
                        }}
                    >
                        <FaCouch />
                    </button>
                </div>
                <ConfirmationModal
                    cancelButtonText={t('cancel')}
                    confirmButtonText={t('confirm')}
                    content={t('payment.question')}
                    onClose={handleClose}
                    onConfirm={handleConfirmCheckout}
                    show={isModalVisible}
                    title={t('payment.title')}
                />
            </div>

            <div className="style-select-gallery">
                <div>
                    <div className="checkbox-container">
                        {styles.map((style) => (
                            <div
                                className="checkbox-container-item"
                                key={style}
                            >
                                <input
                                    checked={false}
                                    className="checkbox"
                                    id={style}
                                    name={style}
                                    onClick={(event) => {
                                        handleAddStyle(event);
                                    }}
                                    type="checkbox"
                                />
                                <label
                                    className="checkbox-label"
                                    htmlFor={style}
                                    name={style}
                                    style={{
                                        backgroundImage: creativeMode
                                            ? `url(${process.env.REACT_APP_THUMBNAILS}/creative/${encodeURIComponent(style.toLowerCase().replace(/(\s+|room)/g, ''))}.jpg)`
                                            : `url(${process.env.REACT_APP_THUMBNAILS}/default/${encodeURIComponent(style.toLowerCase().replace(/(\s+|room)/g, ''))}.jpg)`,
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center'
                                    }}
                                >
                                    {style.toUpperCase()}
                                </label>
                                {selectedStyles[style] && (
                                    <div className="counter">
                                        <button
                                            className="counter-button"
                                            name={style}
                                            onClick={handleRemoveStyle}
                                        >
                                            -
                                        </button>
                                        <span className="counter-value">
                                            {selectedStyles[style]}
                                        </span>
                                        <button
                                            className="counter-button"
                                            name={style}
                                            onClick={handleAddStyle}
                                        >
                                            +
                                        </button>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
                <div
                    style={{
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        position: 'absolute',
                        right: '10px',
                        top: '10px'
                    }}
                ></div>
            </div>
        </Modal>
    );
};

StyleSelectModal.propTypes = {
    ConfirmationModal: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    genCount: PropTypes.number.isRequired,
    handleCheckoutClick: PropTypes.func.isRequired,
    handleCheckoutResume: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleConfirmCheckout: PropTypes.func.isRequired,
    height: PropTypes.number.isRequired,
    isModalOpen: PropTypes.bool.isRequired,
    isModalVisible: PropTypes.bool.isRequired,
    selectedStyles: PropTypes.object.isRequired,
    sessionID: PropTypes.string.isRequired,
    setGenCount: PropTypes.func.isRequired,
    setIsFirstLoading: PropTypes.func.isRequired,
    setIsModalOpen: PropTypes.func.isRequired,
    setItems: PropTypes.func.isRequired,
    setSelectedStyles: PropTypes.func.isRequired,
    styles: PropTypes.array.isRequired,
    upload: PropTypes.string.isRequired,
    uploadRef: PropTypes.func.isRequired,
    width: PropTypes.number.isRequired
};

export default StyleSelectModal;
