import JSZip from 'jszip';
import Modal from 'react-modal';
import React, { useState } from 'react';
import './UploadModal.css';

const Uploader = () => {
    const [error, setError] = useState('');
    const [images, setImages] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);

    const handleImageClick = (image) => {
        setSelectedImage(image);
    };

    const upload = async (event) => {
        setError('');
        setImages([]);
        const file = event.target.files[0];

        if (file && file.name.endsWith('.zip')) {
            try {
                const reader = new FileReader();

                reader.onload = async (e) => {
                    try {
                        const content = e.target.result;
                        const zip = await JSZip.loadAsync(content);
                        const imgFiles = [];

                        zip.forEach((relativePath, file) => {
                            if (file.name.match(/\.(jpg|jpeg|png|gif)$/i)) {
                                imgFiles.push(
                                    file.async('base64').then((base64) => ({
                                        name: file.name,
                                        base64: `data:image/*;base64,${base64}`
                                    }))
                                );
                            }
                        });

                        const imgResults = await Promise.all(imgFiles);
                        setImages(imgResults);
                    } catch (err) {
                        setError('Error processing zip file.');
                    }
                };

                reader.onerror = () => {
                    setError('Error reading file.');
                };

                reader.readAsArrayBuffer(file);
            } catch (err) {
                setError('Error processing zip file.');
            }
        } else {
            setError('Please upload a valid zip file.');
        }
    };

    return (
        <Modal isOpen={true} className="uploader-modal">
            <div className="uploader-container">
                <input
                    accept=".zip"
                    className="uploader-input"
                    onChange={upload}
                    type="file"
                />
                {error && <p className="uploader-error">{error}</p>}
                <div className="uploader-images">
                    {images.map((image, index) => (
                        <div
                            key={index}
                            className="uploader-image"
                            onClick={() => handleImageClick(image)}
                        >
                            <img alt={image.name} src={image.base64} />
                        </div>
                    ))}
                </div>
                {selectedImage && (
                    <Modal
                        className="image-modal"
                        isOpen={true}
                        onRequestClose={() => setSelectedImage(null)}
                    >
                        <img
                            alt={selectedImage.name}
                            src={selectedImage.base64}
                        />
                    </Modal>
                )}
            </div>
        </Modal>
    );
};

export default Uploader;
