import './App.css';
import './i18n.js';
import '@mantine/core/styles.css';
import '@mantine/notifications/styles.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { MantineProvider } from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import React from 'react';
import Stylizer from './components/Stylizer/Stylizer.js';
import Uploader from './components/UploadModal/UploadModal.js';

function App() {
    return (
        <MantineProvider theme="dark" withGlobalStyles withNormalizeCSS>
            <Notifications limit={3} position="top-center" zIndex={1011} />
            <Router>
                <div className="App">
                    <Routes>
                        <Route path="/" element={<Stylizer />} />
                        <Route path="/unzip" element={<Uploader />} />
                    </Routes>
                </div>
            </Router>
        </MantineProvider>
    );
}

export default App;
